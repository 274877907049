import { fromJS, toJS } from "immutable";

import {
    GET_USER_DETAILS_SUCCESS,
    SET_CURRENT_USER_ID,
    ADD_NEW_TEST,
    UPDATE_QUESTION_FIELD,
    CHANGE_NEW_TEST_NAME_STATUS,
    CHANGE_CURRENT_MODE,
    MODES,
    ADMIN_MODES,
    INTERVIEWS_DATA_SUCCESS,
    GET_AUDIT_LOGS_SUCCESS,
    GET_DTREE_KEYS_SUCCESS,
    // ADD_TEST_BUILDER_QUESTION,
    // REMOVE_TEST_BUILDER_QUESTION,
    // REORDER_TEST_BUILDER_QUESTION,
    UPLOAD_INTERVIEW_DATA_RESULT,
    GET_QUESTIONS_SUCCESS,
    UPDATE_TEST_BUILDER_QUESTIONS,
    UPDATE_TEST_BUILDER_TEST,
    GET_SCORECARD_DATA_SUCCESS,
    RESET_TEST,
    UPDATE_TEST_SUBSCRIBERS_LOADER,
    UPDATE_CARD_STATUS,
    COMPILE_CODE,
    COMPILE_CODE_SUCCESS,
    GET_TEST_LIBRARY,
    GET_TEST_LIBRARY_SUCCESS,
    SEARCH_TEST_LIBRARY,
    GET_INSIGHTS_SUCCESS,
    GET_INSIGHTS,
    SEARCH_TEST_LIBRARY_SUCCESS,
    GET_SUBSCRIPTION_INVOICES_SUCCESS,
    ADD_MULTI_AUTH_DETAIL,
    GET_PAIRS_PAGINATED_SUCCESS,
    GET_INTERVIEW_QUESTIONS,
    GET_INTERVIEW_QUESTIONS_SUCCESS,
    GET_PUBLIC_QUESTIONS_SUCCESS,
    GET_TEST_METADATA_SUCCESS,
    CREATE_READY_TEST,
    GET_READY_ASSESSMENT_SUCCESS,
    FETCH_ONBOARDING_DATA_SUCCESS,
    SEND_BULK_INVITES,
    SEND_BULK_INVITES_SUCCESS,
    EXPORT_GRAPH_FETCH,
    EXPORT_GRAPH_FETCH_SUCCESS,
    ADD_QUESTIONS_TO_TEST,
    ADD_QUESTIONS_TO_TEST_RESULT,
    SORT_QUESTIONS_DND,
    SORT_QUESTIONS_DND_RESULT,
    DELETE_QUESTION_TEST,
    DELETE_QUESTION_TEST_RESULT,
    FETCH_RTG_DATA_SUCCESS,
    DELETE_CARD_DETAILS,
    DELETE_CARD_DETAILS_SUCCESS,
    CANCEL_SUBSCRIPTION,
    CANCEL_SUBSCRIPTION_SUCCESS,
    FETCH_SAAS_PLANS_SUCCESS,
    DELETE_TEST_STATUS,
    GET_SCORECARD_IP_DETAILS_SUCCESS,
    GET_SCORECARD_INTERVIEW_DATA_SUCCESS,
    GET_CANDIDATE_FUNNEL_SUCCESS,
    GET_SCORE_DISTRIBUTION_SUCCESS,
    GET_DAYS_DATA_SUCCESS,
    GET_INVITED_BY_SUCCESS,
    GET_AVG_COMPLETION_TIME_SUCCESS,
    GET_AVG_RATINGS_SUCCESS,
    GET_REVIEWS_SUCCESS,
    DELETE_INTERVIEW,
    DELETE_INTERVIEW_SUCCESS,
    GET_SHALLOW_INTERVIEWS_SUCCESS,
    GET_SHALLOW_INTERVIEWS,
    GET_HIGH_LEVEL_INTERVIEWS_TOTAL_SUCCESS,
    GET_INTERVIEW_DETAILS_SUCCESS,
    GET_INTERVIEW_DETAILS,
    CANCEL_INTERVIEW,
    CANCEL_INTERVIEW_SUCCESS,
    END_INTERVIEW,
    END_INTERVIEW_SUCCESS,
    PREVIEW_ASSESSMENT,
    PREVIEW_ASSESSMENT_SUCCESS,
    GET_PREVIEW_TEST,
    GET_PREVIEW_TEST_SUCCESS,
    DELETE_PREVIEW_QUESTION_TEST,
    ADD_NEW_TEST_REQUEST,
    ADD_NEW_TEST_REQUEST_SUCCESS,
    UPLOAD_INTERVIEW_DATA,
    GET_SCORE_TIME_SERIES_SUCCESS,
    UPDATE_TEST_METADATA_LOADER,
    GENERATE_TEST_ONELINK,
    GENERATE_TEST_ONELINK_SUCCESS,
    FETCH_ADDRESS_SUCCESS,
    FETCH_LIVE_UPDATES_SUCCESS,
    FETCH_LIVE_UPDATES,
    FETCH_PROBLEMS,
    FETCH_PROBLEMS_SUCCESS,
    SAVE_PROBLEM,
    SAVE_PROBLEM_SUCCESS,
    SAVE_PROBLEM_FAILURE,
    SET_EDIT_PROBLEM,
    CLEAR_SAVED_PROBLEM,
    FETCH_SUBMISSIONS,
    FETCH_SUBMISSIONS_SUCCESS,
    UPDATE_CREDITS_MANUALLY_STATUS,
    UPDATE_QUESTION_FIELD_STATUS,
    REVIEW_BULK_INTERVIEWS,
    REVIEW_BULK_INTERVIEWS_STATUS,
    CANCEL_BULK_INTERVIEWS,
    CANCEL_BULK_INTERVIEWS_STATUS,
    SEND_BULK_RESULT_EMAIL_TO_CANDIDATE,
    SEND_BULK_RESULT_EMAIL_TO_CANDIDATE_STATUS,
    SET_EDIT_CUSTOM_QUESTION,
    CUSTOMIZE_QUESTIONS_TO_TEST_RESULT,
    VERIFY_QUESTION_SUCCESS,
    FETCH_NOTIFICATION_FEED,
    FETCH_NOTIFICATION_FEED_STATUS,
    GET_QUESTIONS_DATA_TEST_SUCCESS,
    GET_ADDITIONAL_TESTS_DATA_SUCCESS,
    FETCH_ADA_AI_CONTACTS,
    FETCH_ADA_AI_CONTACTS_STATUS,
    FETCH_ADA_AI_CONTACT_DOCUMENTS,
    FETCH_ADA_AI_CONTACT_DOCUMENTS_STATUS,
    FETCH_ADA_AI_CONTACT_SESSIONS,
    FETCH_ADA_AI_CONTACT_SESSIONS_STATUS,
    CREATE_ADA_AI_QUERY,
    CREATE_ADA_AI_QUERY_STATUS,
    UPSERT_DOCUMENTS,
    UPSERT_DOCUMENTS_STATUS,
    GET_QUESTIONS_DATA_TEST,
    UPDATE_FAQS_STATUS,
    UPDATE_FAQS,
    REVIEW_INTERVIEW_SUCCESS,
    UPLOAD_INTERVIEW_DATA_SUCCESS,
    GET_QUESTIONS_DATA,
    GET_ALL_CATEGORIES_SUCCESS,
    UPDATE_BILLING_DETAILS,
    UPDATE_BILLING_DETAILS_SUCCESS,
    GET_SELECT_REVIEWS_SUCCESS,
    GET_SELECT_REVIEWS,
    UPDATE_BILLING_DETAILS_ERROR,
    GET_BLOCKED_EMAILS_SUCCESS,
    DELETE_BLOCKED_EMAIL_SUCCESS,
} from "./constants";

import { questions } from "./samples";

const initialState = fromJS({
    users: {},
    currentUserId: "",
    newTestNameStatus: "",
    currentMode: MODES.DEFAULT,
    currentModeMetadata: null,
    adminModes: ADMIN_MODES,
    interviews: {
        data: {},
    },
    pairs: {
        data: [],
    },
    interviewQuestions: {
        data: [],
        fetched: true,
    },
    questions: {},
    libraryQuestions: {
        filters: {},
        questions: [],
        searchResults: [],
        next: null,
        total: 0,
        status: "",
    },
    testLibrary: {
        categories: [],
        tests: [],
        testsMap: {},
        searchInProgress: false,
        searchTests: [],
        searchReadyTests: [],
    },
    testBuilder: {
        questions: [],
        testId: null,
        roleId: null,
        dtreeKey: null,
        displayName: "",
        threshold: 55,
        timeboxed: true,
        timeboxStyle: "DEFAULT",
        proctoringMode: true,
        subscribers: [],
    },
    scorecards: {},
    loadedInterviewsData: false,
    shallowInterviewsDataStatus: "",
    loadedPairsData: false,
    cardUpdate: {},
    testSubscribersLoader: false,
    testMetadataUpdateLoader: false,
    glotResults: {},
    insights: {},
    authData: {},
    stripe: {},
    apiRequests: {},
    publicQuestions: {},
    readyAssessments: {},
    onboardingData: {},
    bulkInvites: {
        loading: false,
        data: {},
    },
    exportGraph: {
        fetching: false,
    },
    addQuestions: {
        uploading: false,
        result: {},
    },
    sortQuestions: {
        uploading: false,
        result: {},
    },
    deleteQuestion: {
        deleting: false,
        result: {},
    },
    readyToGoData: {},
    deleteCardDetails: {
        status: "",
    },
    cancelSubscription: {
        status: "",
    },
    saasPlans: {},
    deleteTestStatus: "",
    modalStatus: { status: "", action: "" },
    shallowInterviews: { table: {}, kanban: {} },
    previewAssessmentStatus: { status: "", testId: "" },
    previewTests: {},
    newCustomTestRequestStatus: "",
    newCustomTestRequestUrl: "",
    onelinkStatus: "",
    liveUpdates: { status: "", data: {} },
    problems: { status: "", problemDetails: [] },
    saveProblem: { status: "", error: "", success: "" },
    editProblem: {},
    submissions: { status: "", submissionDetails: [], submissionCount: 0 },
    reviewBulkInterviewsStatus: {},
    editCustomQuestion: null,
    notificationFeed: { status: "", data: {} },
    accountDeactivated: false,
    additionalTests: [],
    contacts: { status: "", data: {} },
    documents: { status: "", data: {} },
    sessions: { status: "", data: {} },
    query: {},
    upsert: { overallStatus: "", eachStatus: [] },
    questionsTestStatus: "",
    updateFaqsStatus: "",
    testsFetched: {},
    oneshot: {
        reviews: [],
    },
    blockedEmails: [],
});

const baseQuestionInDB = {
    questionId: "",
    dtreeKey: "",
    metadata: {
        completionRate: 80,
        difficulty: 2,
        displayName: "",
        questionInMd: "",
        skills: [],
        timeToSolve: 0,
        type: "",
    },
};

const baseQuestion = {
    questionId: "",
    restrictLanguage: null,
    timeboxed: false, // added from one var for all questions
    // dtreeKey: "" added later
};

const baseUser = {
    apiKeys: [],
    userId: null,
    savedTests: [],
    displayName: "",
    allowedModes: [],
    users: {},
};

const baseTest = {
    questions: [],
    testId: null,
    roleId: null,
    dtreeKey: null,
    displayName: "",
};

const baseRole = {
    displayName: "",
    dtreeKey: "",
    roleId: "",
};

function dashboardReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_RTG_DATA_SUCCESS:
            return state.setIn(["readyToGoData"], fromJS(action.data));
        case FETCH_SAAS_PLANS_SUCCESS:
            return state.setIn(["saasPlans"], fromJS(action.data));
        case DELETE_QUESTION_TEST:
            return state.setIn(["deleteQuestion", "deleting"], true);
        case DELETE_QUESTION_TEST_RESULT:
            return state
                .setIn(["deleteQuestion", "deleting"], false)
                .setIn(["deleteQuestion", "result"], fromJS(action))
                .setIn(
                    ["users", action.companyId, "savedTests"],
                    state
                        .getIn(
                            ["users", action.companyId, "savedTests"],
                            fromJS([])
                        )
                        .map((t) => {
                            if (
                                action.success &&
                                action.test &&
                                action.test.testId === t.get("testId")
                            ) {
                                return t.merge(t, fromJS(action.test || {}));
                            } else {
                                return t;
                            }
                        })
                );
        case DELETE_PREVIEW_QUESTION_TEST:
            return state
                .setIn(
                    ["previewTests", action.testId, "questions"],
                    action.questions
                )
                .setIn(
                    ["previewTests", action.testId, "dtreesDetails"],
                    action.dtreesDetails
                );
        case VERIFY_QUESTION_SUCCESS:
            return state.setIn(
                ["questions"],
                state
                    .get("questions", fromJS({}))
                    .merge(fromJS(action.newQuestions || {}))
            );
        case ADD_QUESTIONS_TO_TEST:
            return state.setIn(["addQuestions", "uploading"], true);
        case ADD_QUESTIONS_TO_TEST_RESULT:
            return state
                .setIn(["addQuestions", "uploading"], false)
                .setIn(["addQuestions", "error"], action.error)
                .setIn(["addQuestions", "result"], fromJS(action))
                .setIn(
                    ["questions"],
                    state
                        .get("questions", fromJS({}))
                        .merge(fromJS(action.newQuestions || {}))
                )
                .setIn(
                    ["users", action.companyId, "savedTests"],
                    state
                        .getIn(
                            ["users", action.companyId, "savedTests"],
                            fromJS([])
                        )
                        .map((t) => {
                            if (
                                action.success &&
                                action.testId === t.get("testId") &&
                                action.updatedTest
                            ) {
                                return t.merge(
                                    t,
                                    fromJS(action.updatedTest || {})
                                );
                            } else {
                                return t;
                            }
                        })
                );
        case CUSTOMIZE_QUESTIONS_TO_TEST_RESULT:
            return state
                .setIn(["addQuestions", "uploading"], false)
                .setIn(["addQuestions", "error"], action.error)
                .setIn(["addQuestions", "result"], fromJS(action))
                .setIn(
                    ["users", action.companyId, "savedTests"],
                    state
                        .getIn(
                            ["users", action.companyId, "savedTests"],
                            fromJS([])
                        )
                        .map((t) => {
                            if (
                                action.success &&
                                action.testId === t.get("testId") &&
                                action.updatedTest
                            ) {
                                return t.merge(
                                    t,
                                    fromJS(action.updatedTest || {})
                                );
                            } else {
                                return t;
                            }
                        })
                );
        case SORT_QUESTIONS_DND:
            return state.setIn(["sortQuestions", "uploading"], true);
        case SORT_QUESTIONS_DND_RESULT:
            return state
                .setIn(["sortQuestions", "uploading"], false)
                .setIn(
                    ["sortQuestions", "result"],
                    fromJS({ success: action.success, error: action.error })
                );

        case EXPORT_GRAPH_FETCH:
            return state.setIn(["exportGraph", "fetching"], true);
        case EXPORT_GRAPH_FETCH_SUCCESS:
            return state
                .setIn(["exportGraph", "fetching"], false)
                .setIn(["exportGraph", "data"], fromJS(action.data));
        case SEND_BULK_INVITES:
            return state.setIn(["bulkInvites", "loading"], true);
        case SEND_BULK_INVITES_SUCCESS: {
            return action.view === "table"
                ? state.setIn(["bulkInvites", "loading"], false)
                : state
                      .setIn(
                          ["shallowInterviews", action.view, "invited"],
                          state
                              .getIn(
                                  ["shallowInterviews", action.view, "invited"],
                                  fromJS([])
                              )
                              .unshift(...action.data.map((d) => fromJS(d)))
                      )
                      .setIn(["bulkInvites", "loading"], false);
        }
        case FETCH_ONBOARDING_DATA_SUCCESS:
            return state.setIn(["onboardingData"], fromJS(action.data || {}));
        case CREATE_READY_TEST:
            return state.setIn(["readyAssessments", "loading"], true);
        case GET_READY_ASSESSMENT_SUCCESS:
            return state
                .setIn(["readyAssessments", "loading"], false)
                .setIn(["readyAssessments", "testId"], action.testId)
                .setIn(
                    ["questions"],
                    state
                        .get("questions", fromJS({}))
                        .merge(fromJS(action.questions || {}))
                );
        case UPLOAD_INTERVIEW_DATA:
            return state.setIn(
                ["apiRequests", "interview_upload", "status"],
                true
            );
        case UPLOAD_INTERVIEW_DATA_RESULT:
            return state
                .setIn(["apiRequests", "interview_upload", "status"], false)
                .setIn(
                    ["apiRequests", "interview_upload", "single"],
                    fromJS(action)
                );
        case UPLOAD_INTERVIEW_DATA_SUCCESS:
            return action.view !== "table"
                ? state.setIn(
                      ["shallowInterviews", action.view, "invited"],
                      state
                          .getIn(
                              ["shallowInterviews", action.view, "invited"],
                              fromJS([])
                          )
                          .insert(0, fromJS(action.candidate))
                  )
                : state;
        case GET_SUBSCRIPTION_INVOICES_SUCCESS:
            return state.setIn(
                ["stripe"],
                fromJS({
                    subscription: action.subscription,
                    invoices: action.invoices,
                })
            );
        case ADD_MULTI_AUTH_DETAIL:
            return state.setIn(["authData"], fromJS(action.authData || {}));
        case GET_TEST_METADATA_SUCCESS:
            return state
                .setIn(["testMetadata"], fromJS({}))
                .setIn(["testMetadata", action.testId], fromJS(action.data));
        case GET_PUBLIC_QUESTIONS_SUCCESS:
            return state
                .setIn(
                    ["publicQuestions", "questions", action.testId],
                    fromJS(action.questions || [])
                )
                .setIn(
                    ["publicQuestions", "allSkills"],
                    fromJS(action.allSkills || {})
                )
                .setIn(
                    ["publicQuestions", "allCategories"],
                    fromJS(action.allCategories || {})
                );
        case GET_TEST_LIBRARY_SUCCESS:
            return state
                .setIn(["testLibrary", "categories"], fromJS(action.categories))
                .setIn(["testLibrary", "tests"], fromJS(action.tests))
                .setIn(
                    ["testLibrary", "testsMap"],
                    fromJS(action.testsMap || {})
                );
        case GET_ADDITIONAL_TESTS_DATA_SUCCESS:
            return state.set(
                "additionalTests",
                fromJS(action.additionalTests || [])
            );
        case SEARCH_TEST_LIBRARY_SUCCESS:
            return state
                .setIn(["testLibrary", "searchInProgress"], false)
                .setIn(["testLibrary", "searchTests"], fromJS(action.tests))
                .setIn(
                    ["testLibrary", "searchReadyTests"],
                    fromJS(action.readyTests)
                );
        case SEARCH_TEST_LIBRARY:
            return state.setIn(["testLibrary", "searchInProgress"], true);
        case GET_SELECT_REVIEWS:
            return state.setIn(["oneshot", "status"], "inprogress");
        case GET_SELECT_REVIEWS_SUCCESS:
            return state
                .setIn(["oneshot", "reviews"], fromJS(action.reviews))
                .setIn(["oneshot", "data"], fromJS(action.oneShotData))
                .setIn(["oneshot", "status"], "complete");
        case GET_INSIGHTS_SUCCESS:
            return state.setIn(
                ["insights", "data", action.testId || "all"],
                fromJS(action.data)
            );
        case GET_CANDIDATE_FUNNEL_SUCCESS:
            return state.setIn(
                ["insights", "candidateFunnel", action.testId || "all"],
                fromJS(action.data)
            );
        case GET_SCORE_DISTRIBUTION_SUCCESS:
            return state.setIn(
                ["insights", "scoreDistribution", action.testId || "all"],
                fromJS(action.data)
            );
        case GET_DAYS_DATA_SUCCESS:
            return state.setIn(
                [
                    "insights",
                    "tieredCompletionDaysCount",
                    action.testId || "all",
                ],
                fromJS(action.data)
            );
        case GET_INVITED_BY_SUCCESS:
            return state.setIn(
                ["insights", "tieredInvitedBy", action.testId || "all"],
                fromJS(action.data)
            );
        case GET_AVG_COMPLETION_TIME_SUCCESS:
            return state.setIn(
                ["insights", "averageTimeToComplete", action.testId || "all"],
                fromJS(action.data)
            );
        case GET_AVG_RATINGS_SUCCESS:
            return state.setIn(
                ["insights", "ratings", action.testId || "all"],
                fromJS(action.data)
            );
        case GET_REVIEWS_SUCCESS:
            return state.setIn(
                ["insights", "displayReviews", action.testId || "all"],
                fromJS(action.data)
            );
        case GET_SCORE_TIME_SERIES_SUCCESS:
            return state.setIn(
                ["insights", "scoreTimeSeries", action.testId || "all"],
                fromJS(action.data)
            );
        case GET_USER_DETAILS_SUCCESS:
            return state
                .set(
                    "accountDeactivated",
                    !!(action.data || {}).accountDeactivated
                )
                .setIn(["users", action.userId], fromJS(action.data));
        case SET_CURRENT_USER_ID:
            return state.setIn(["currentUserId"], action.userId);
        case UPDATE_QUESTION_FIELD_STATUS:
            return action.isMetadata
                ? state
                      .setIn(
                          [
                              "questions",
                              action.questionId,
                              "metadata",
                              action.field,
                          ],
                          fromJS(action.value)
                      )
                      .setIn(
                          ["libraryQuestions", "questions"],
                          state
                              .getIn(
                                  ["libraryQuestions", "questions"],
                                  fromJS([])
                              )
                              .map((q) => {
                                  if (
                                      q.get("questionId") === action.questionId
                                  ) {
                                      return q.setIn(
                                          ["metadata", action.field],
                                          fromJS(action.value)
                                      );
                                  } else {
                                      return q;
                                  }
                              })
                      )
                : state
                      .setIn(
                          ["questions", action.questionId, action.field],
                          fromJS(action.value)
                      )
                      .setIn(
                          ["libraryQuestions", "questions"],
                          state
                              .getIn(
                                  ["libraryQuestions", "questions"],
                                  fromJS([])
                              )
                              .map((q) => {
                                  if (
                                      q.get("questionId") === action.questionId
                                  ) {
                                      return q.set(
                                          action.field,
                                          fromJS(action.value)
                                      );
                                  } else {
                                      return q;
                                  }
                              })
                      );
        case CHANGE_NEW_TEST_NAME_STATUS:
            return state.setIn(["newTestNameStatus"], action.status);

        case CHANGE_CURRENT_MODE:
            return state
                .set("currentMode", action.mode)
                .set("currentModeMetadata", action.currentModeMetadata);

        case GET_INTERVIEW_DETAILS:
            return state.setIn(["interviews", "status"], false);

        case GET_INTERVIEW_DETAILS_SUCCESS:
            return state
                .set(
                    "accountDeactivated",
                    !!(action.data || {}).accountDeactivated
                )
                .setIn(
                    ["interviews", "data"],
                    state
                        .getIn(["interviews", "data"], fromJS({}))
                        .merge(fromJS(action.data || {}))
                )
                .setIn(["interviews", "status"], true);
        case INTERVIEWS_DATA_SUCCESS:
            return state
                .set("interviews", fromJS(action.interviews))
                .set("loadedInterviewsData", true);
        case GET_SHALLOW_INTERVIEWS:
            return state.set(
                "shallowInterviewsDataStatus",
                action.view === "table"
                    ? "table"
                    : (action.filters || {}).fetchInterviewColumn
                    ? (action.filters || {}).fetchInterviewColumn
                    : "all"
            );
        case GET_SHALLOW_INTERVIEWS_SUCCESS: {
            if (action.view !== "table") {
                var mergedInterviews = state
                    .getIn(["shallowInterviews", action.view], fromJS({}))
                    .toJS();
                Object.keys(action.interviews || {}).forEach((column) => {
                    mergedInterviews[column] =
                        (action.filters.candidateName ||
                            action.filters.testId ||
                            action.filters.endDate ||
                            action.filters.startDate) &&
                        action.pageNo === 1
                            ? action.interviews[column] || []
                            : [
                                  ...(mergedInterviews[column] || []),
                                  ...(action.interviews[column] || []).filter(
                                      (interview) =>
                                          !(
                                              mergedInterviews[column] || []
                                          ).find(
                                              (i) =>
                                                  i.interviewId ===
                                                  interview.interviewId
                                          )
                                  ),
                              ];
                });

                return state
                    .setIn(
                        ["shallowInterviews", action.view],
                        fromJS(mergedInterviews)
                    )
                    .set("loadedInterviewsData", true)
                    .set("accountDeactivated", !!action.accountDeactivated)
                    .set("shallowInterviewsDataStatus", "success");
            }

            return state
                .setIn(
                    ["shallowInterviews", action.view],
                    state
                        .getIn(["shallowInterviews", action.view], fromJS({}))
                        .merge(fromJS(action.interviews || {}))
                )
                .set("loadedInterviewsData", true)
                .set("accountDeactivated", !!action.accountDeactivated)
                .set("shallowInterviewsDataStatus", "success");
        }
        case GET_HIGH_LEVEL_INTERVIEWS_TOTAL_SUCCESS:
            return state.setIn(
                ["shallowInterviews"],
                state
                    .get("shallowInterviews", fromJS({}))
                    .merge(fromJS({ total: action.total } || {}))
            );
        case REVIEW_INTERVIEW_SUCCESS: {
            const interview =
                state
                    .getIn(
                        ["shallowInterviews", action.view, action.fromColumn],
                        fromJS([])
                    )
                    .filter(
                        (i) => i.get("interviewId") === action.interviewId
                    ) || fromJS([]);

            if (interview.size > 0) {
                return state
                    .setIn(
                        ["shallowInterviews", action.view, action.toColumn],
                        state
                            .getIn(
                                [
                                    "shallowInterviews",
                                    action.view,
                                    action.toColumn,
                                ],
                                fromJS([])
                            )
                            .insert(0, fromJS(interview.get(0)))
                    )
                    .setIn(
                        ["shallowInterviews", action.view, action.fromColumn],
                        state
                            .getIn(
                                [
                                    "shallowInterviews",
                                    action.view,
                                    action.fromColumn,
                                ],
                                fromJS([])
                            )
                            .filter(
                                (i) =>
                                    i.get("interviewId") !== action.interviewId
                            )
                    );
            }
        }
        case SEND_BULK_RESULT_EMAIL_TO_CANDIDATE:
            return state.set(
                "modalStatus",
                fromJS({ status: "in-progress", action: "bulkEmail" })
            );
        case SEND_BULK_RESULT_EMAIL_TO_CANDIDATE_STATUS:
            return state.set(
                "modalStatus",
                fromJS({ status: action.status, action: "bulkEmail" })
            );
        case END_INTERVIEW:
            return state.set(
                "modalStatus",
                fromJS({ status: "in-progress", action: "endInterview" })
            );
        case END_INTERVIEW_SUCCESS:
            return state.set(
                "modalStatus",
                fromJS({ status: action.status, action: "endInterview" })
            );
        case CANCEL_INTERVIEW:
            return state.set(
                "modalStatus",
                fromJS({ status: "in-progress", action: "cancelInterview" })
            );
        case CANCEL_INTERVIEW_SUCCESS:
            return action.view === "table"
                ? state
                      .setIn(
                          ["shallowInterviews", "data"],
                          (
                              state.getIn(["shallowInterviews", "data"]) ||
                              fromJS([])
                          ).map((i) => {
                              if (i.get("interviewId") === action.interviewId) {
                                  return i.merge(
                                      i,
                                      fromJS({
                                          cancelled: 1,
                                          interviewEndTime: Date.now(),
                                      })
                                  );
                              }
                              return i;
                          })
                      )
                      .set(
                          "modalStatus",
                          fromJS({
                              status: "success",
                              action: "cancelInterview",
                          })
                      )
                : state
                      .setIn(
                          ["shallowInterviews", action.view, "invited"],
                          (
                              state.getIn(
                                  ["shallowInterviews", action.view, "invited"],
                                  fromJS([])
                              ) || fromJS([])
                          ).filter(
                              (i) => i.get("interviewId") !== action.interviewId
                          )
                      )
                      .setIn(
                          ["shallowInterviews", "total", "invitedCount"],
                          state.getIn([
                              "shallowInterviews",
                              "total",
                              "invitedCount",
                          ]) - 1
                      )
                      .set(
                          "modalStatus",
                          fromJS({
                              status: "success",
                              action: "cancelInterview",
                          })
                      );

        case DELETE_INTERVIEW:
            return state.set(
                "modalStatus",
                fromJS({ status: "in-progress", action: "deleteInterview" })
            );
        case DELETE_INTERVIEW_SUCCESS:
            return state
                .setIn(
                    [
                        "shallowInterviews",
                        action.view,
                        action.view === "table" ? "data" : action.fromColumn,
                    ],
                    (
                        state.getIn([
                            "shallowInterviews",
                            action.view,
                            action.view === "table"
                                ? "data"
                                : action.fromColumn,
                        ]) || fromJS([])
                    ).map((i) => {
                        if (i.get("interviewId") === action.interviewId) {
                            return i.merge(
                                i,
                                fromJS({
                                    candidateEmailId:
                                        i.get("interviewId") +
                                        "candidate@adaface.com",
                                    candidateName: "-",
                                })
                            );
                        }
                        return i;
                    })
                )
                .setIn(
                    ["interviews", "data", action.interviewId],
                    state
                        .getIn(["interviews", "data", action.interviewId])
                        .merge(
                            fromJS({
                                candidateEmailId:
                                    action.interviewId +
                                    "candidate@adaface.com",
                                candidateName: "-",
                            })
                        )
                )

                .set(
                    "modalStatus",
                    fromJS({ status: "success", action: "deleteInterview" })
                );

        case GET_PAIRS_PAGINATED_SUCCESS:
            return state
                .set("pairs", fromJS(action.interviews))
                .set("loadedPairsData", true);
        case GET_AUDIT_LOGS_SUCCESS:
            return state.set("auditLogs", fromJS(action.data));
        case UPDATE_TEST_BUILDER_TEST:
            return (
                state
                    .setIn(["testBuilder", "displayName"], action.displayName)
                    .setIn(["testBuilder", "testId"], action.testId)
                    .setIn(
                        ["testBuilder", "questions"],
                        fromJS(action.questions)
                    )
                    .setIn(["testBuilder", "roleId"], action.roleId)
                    .setIn(["testBuilder", "timeboxStyle"], action.timeboxStyle)
                    .setIn(["testBuilder", "threshold"], action.threshold)
                    // .setIn(['testBuilder', 'maxTimeInMinutes'], action.maxTimeInMinutes)
                    .setIn(["testBuilder", "timeboxed"], action.timeboxed)
                    .setIn(
                        ["testBuilder", "proctoringMode"],
                        action.proctoringMode
                    )
                    .setIn(
                        ["testBuilder", "subscribers"],
                        fromJS(action.subscribers || [])
                    )
                    .setIn(
                        ["testBuilder", "requestId"],
                        action.requestId || false
                    )
            );
        case GET_ALL_CATEGORIES_SUCCESS:
            return state.setIn(
                ["libraryQuestions", "allCategories"],
                fromJS(action.categories || [])
            );
        case GET_QUESTIONS_DATA:
            return state
                .setIn(["libraryQuestions", "status"], "in-progress")
                .setIn(
                    ["libraryQuestions", "searchResults"],
                    action.pageNo === 1
                        ? fromJS([])
                        : state.getIn(
                              ["libraryQuestions", "searchResults"],
                              fromJS([])
                          )
                );
        case GET_QUESTIONS_SUCCESS:
            return state
                .setIn(
                    ["libraryQuestions", "questions"],
                    fromJS(
                        state
                            .getIn(
                                ["libraryQuestions", "questions"],
                                fromJS([])
                            )
                            .concat(
                                fromJS(
                                    (action.data.questions || []).filter(
                                        (q) =>
                                            !state
                                                .getIn(
                                                    [
                                                        "libraryQuestions",
                                                        "questions",
                                                    ],
                                                    fromJS([])
                                                )
                                                .find(
                                                    (q1) =>
                                                        q1.get("questionId") ===
                                                        q.questionId
                                                )
                                    )
                                )
                            )
                    )
                )
                .setIn(
                    ["libraryQuestions", "searchResults"],
                    action.data.filters && action.pageNo === 1
                        ? fromJS(action.data.questions || [])
                        : fromJS(
                              state
                                  .getIn(
                                      ["libraryQuestions", "searchResults"],
                                      fromJS([])
                                  )
                                  .concat(
                                      fromJS(
                                          (action.data.questions || []).filter(
                                              (q) =>
                                                  !state
                                                      .getIn(
                                                          [
                                                              "libraryQuestions",
                                                              "searchResults",
                                                          ],
                                                          fromJS([])
                                                      )
                                                      .find(
                                                          (q1) =>
                                                              q1.get(
                                                                  "questionId"
                                                              ) === q.questionId
                                                      )
                                          )
                                      )
                                  )
                          )
                )
                .setIn(["libraryQuestions", "status"], action.data.status || "")
                .setIn(["libraryQuestions", "next"], action.data.next || null)
                .setIn(["libraryQuestions", "total"], action.data.total || 0);
        case GET_QUESTIONS_DATA_TEST:
            return state.set("questionsTestStatus", "in-progress");
        case GET_QUESTIONS_DATA_TEST_SUCCESS:
            return state
                .set("questionsTestStatus", "success")
                .setIn(
                    ["questions"],
                    state
                        .get("questions", fromJS({}))
                        .merge(fromJS(action.questions || {}))
                )
                .setIn(
                    ["libraryQuestions", "questions"],
                    state
                        .getIn(["libraryQuestions", "questions"], fromJS([]))
                        .concat(
                            fromJS(
                                Object.values(action.questions || []).filter(
                                    (q) =>
                                        q &&
                                        !state
                                            .getIn(
                                                [
                                                    "libraryQuestions",
                                                    "questions",
                                                ],
                                                fromJS([])
                                            )
                                            .find(
                                                (q1) =>
                                                    q1.get("questionId") ===
                                                    q.questionId
                                            )
                                )
                            )
                        )
                )
                .set(
                    "testsFetched",
                    state.get("testsFetched").set(action.testId, true)
                );

        case GET_INTERVIEW_QUESTIONS_SUCCESS:
            return state
                .setIn(
                    ["interviewQuestions", "data"],
                    fromJS(action.questions || {})
                )
                .setIn(["interviewQuestions", "fetched"], true);
        case GET_INTERVIEW_QUESTIONS:
            return state.setIn(["interviewQuestions", "fetched"], false);
        case UPDATE_TEST_BUILDER_QUESTIONS:
            return state.setIn(
                ["testBuilder", "questions"],
                fromJS(action.questions || [])
            );
        case RESET_TEST:
            return state.setIn(["testBuilder"], fromJS(baseTest));
        case GET_SCORECARD_DATA_SUCCESS:
            return state.setIn(
                ["scorecards", action.interviewId],
                fromJS(action.interviewData)
            );
        case GET_SCORECARD_IP_DETAILS_SUCCESS:
            return state.setIn(
                ["scorecards", action.interviewId, "ipProctoringDetails"],
                fromJS({ status: "success", data: action.ipProctoringDetails })
            );
        case GET_SCORECARD_INTERVIEW_DATA_SUCCESS:
            return state
                .setIn(
                    ["scorecards", action.interviewId, "interviewData"],
                    fromJS({
                        status: "success",
                        data: action.interviewData.interviewData,
                    })
                )
                .setIn(
                    ["scorecards", action.interviewId, "status"],
                    fromJS(action.interviewData.status)
                );

        case UPDATE_CARD_STATUS:
            return state.setIn(
                ["cardStatus"],
                fromJS({
                    status: action.status,
                    message: action.message,
                    subscription: fromJS(action.subscription || {}),
                })
            );
        case UPDATE_TEST_SUBSCRIBERS_LOADER:
            return state.set("testSubscribersLoader", !!action.show);
        case UPDATE_TEST_METADATA_LOADER:
            return state.set("testMetadataUpdateLoader", !!action.show);
        case DELETE_TEST_STATUS:
            return state.set("deleteTestStatus", action.status || "");
        case COMPILE_CODE:
            return state.setIn(
                [
                    "glotResults",
                    action.interviewId,
                    action.index,
                    "compilationStatus",
                ],
                true
            );
        case COMPILE_CODE_SUCCESS:
            const currentResults =
                state.getIn([
                    "glotResults",
                    action.interviewId,
                    action.index,
                ]) || fromJS({});
            return state.setIn(
                ["glotResults", action.interviewId, action.index],
                currentResults
                    .set("glotstdout", action.glotstdout)
                    .set("glotstderr", action.glotstderr)
                    .set("gloterror", action.gloterror)
                    .set("compilationStatus", false)
            );
        case DELETE_CARD_DETAILS:
            return state.setIn(["deleteCardDetails", "status"], "in-progress");
        case DELETE_CARD_DETAILS_SUCCESS:
            return state
                .setIn(["deleteCardDetails", "status"], "success")
                .setIn(["users", "stripe"], fromJS({}));
        case UPDATE_BILLING_DETAILS:
            return state.setIn(
                ["updateBillingDetails", "status"],
                "in-progress"
            );
        case UPDATE_BILLING_DETAILS_SUCCESS:
            return state
                .setIn(["updateBillingDetails", "status"], "success")
                .setIn(
                    ["users", action.companyId, "stripe"],
                    fromJS(action.updatedBillingDetails || {})
                );
        case UPDATE_BILLING_DETAILS_ERROR:
            return state.setIn(["updateBillingDetails", "status"], "error");
        case CANCEL_SUBSCRIPTION:
            return state.setIn(["cancelSubscription", "status"], "in-progress");
        case CANCEL_SUBSCRIPTION_SUCCESS:
            return state.setIn(["cancelSubscription", "status"], "success");
        case ADD_NEW_TEST_REQUEST:
            return state.set("newCustomTestRequestStatus", "in-progress");
        case ADD_NEW_TEST_REQUEST_SUCCESS:
            return state
                .set("newCustomTestRequestStatus", action.status || "success")
                .set("newCustomTestRequestUrl", action.testUrl || "");

        case PREVIEW_ASSESSMENT:
            return state.setIn(
                ["previewAssessmentStatus", "status"],
                "in-progress"
            );
        case PREVIEW_ASSESSMENT_SUCCESS:
            return state
                .set(
                    "previewAssessmentStatus",
                    fromJS({
                        status: action.status,
                        testId: action.testId || "",
                    })
                )
                .set(
                    "previewTests",
                    state
                        .get("previewTests", fromJS({}))
                        .merge(fromJS(action.newPreviewTest || {}))
                )
                .set(
                    "questions",
                    state
                        .get("questions", fromJS({}))
                        .concat(fromJS(action.questionsDetails || {}))
                );

        case GET_PREVIEW_TEST:
            return state.setIn(
                ["previewAssessmentStatus", "status"],
                "in-progress"
            );
        case GET_PREVIEW_TEST_SUCCESS:
            return state
                .set(
                    "previewAssessmentStatus",
                    fromJS({
                        status: action.status,
                        testId: action.testId || "",
                    })
                )
                .set(
                    "previewTests",
                    state
                        .get("previewTests", fromJS({}))
                        .concat(fromJS(action.previewTest || {}))
                )
                .set(
                    "questions",
                    state
                        .get("questions", fromJS({}))
                        .concat(fromJS(action.questionsDetails || {}))
                );
        case GENERATE_TEST_ONELINK:
            return state.set("onelinkStatus", "in-progress");
        case GENERATE_TEST_ONELINK_SUCCESS:
            return state.set("onelinkStatus", action.status || "");
        case FETCH_ADDRESS_SUCCESS:
            return state.setIn(
                ["users", action.companyId, "stripe", "address"],
                fromJS(action.address || {})
            );
        case FETCH_LIVE_UPDATES:
            return state.setIn(["liveUpdates", "status"], "in-progress");
        case FETCH_LIVE_UPDATES_SUCCESS:
            return state.set(
                "liveUpdates",
                fromJS({
                    status: action.status,
                    data: fromJS({ ...(action.liveUpdates || {}) }),
                })
            );
        case FETCH_PROBLEMS:
            return state.setIn(["problems", "status"], "in-progress");
        case FETCH_PROBLEMS_SUCCESS:
            return state.set(
                "problems",
                fromJS({
                    status: action.status,
                    problemDetails: action.problems,
                })
            );
        case SET_EDIT_PROBLEM:
            return state.set(
                "editProblem",
                fromJS({ problem: action.problem })
            );
        case SAVE_PROBLEM:
            return state.setIn(["saveProblem", "status"], "in-progress");
        case SAVE_PROBLEM_SUCCESS:
            return state.set(
                "saveProblem",
                fromJS({
                    status: "success",
                    success: action.success,
                    error: null,
                })
            );
        case SAVE_PROBLEM_FAILURE:
            return state.set(
                "saveProblem",
                fromJS({
                    status: "failure",
                    success: action.success,
                    error: action.error,
                })
            );
        case CLEAR_SAVED_PROBLEM:
            return state.set(
                "saveProblem",
                fromJS({ status: "", error: null, success: "" })
            );
        case FETCH_SUBMISSIONS:
            return state.setIn(["submissions", "status"], "in-progress");
        case FETCH_SUBMISSIONS_SUCCESS:
            return state.set(
                "submissions",
                fromJS({
                    status: action.status,
                    submissionDetails: action.submissions,
                    submissionCount: action.submissionCount,
                })
            );
        case UPDATE_CREDITS_MANUALLY_STATUS:
            return state
                .setIn(
                    ["users", action.companyId, "credits", "amount"],
                    state.getIn(
                        ["users", action.companyId, "credits", "amount"],
                        0
                    ) + (action.credit || 0)
                )
                .setIn(
                    [
                        "users",
                        action.companyId,
                        "settings",
                        "correctionCredits",
                        "unusedCredits",
                    ],
                    state.getIn(
                        [
                            "users",
                            action.companyId,
                            "settings",
                            "correctionCredits",
                            "unusedCredits",
                        ],
                        0
                    ) - (action.credit || 0)
                );
        case REVIEW_BULK_INTERVIEWS:
            return state.set(
                "reviewBulkInterviewsStatus",
                fromJS({ action: action.action, status: "in-progress" })
            );
        case REVIEW_BULK_INTERVIEWS_STATUS: {
            return state.setIn(
                ["reviewBulkInterviewsStatus", "status"],
                action.status
            );
        }
        case CANCEL_BULK_INTERVIEWS:
            return state.set(
                "modalStatus",
                fromJS({ status: "in-progress", action: "bulk-cancel" })
            );
        case CANCEL_BULK_INTERVIEWS_STATUS:
            return state
                .setIn(
                    ["shallowInterviews", "table", "data"],
                    (
                        state.getIn(["shallowInterviews", "table", "data"]) ||
                        fromJS([])
                    ).map((i) => {
                        if (
                            (action.interviewIds || []).includes(
                                i.get("interviewId")
                            )
                        ) {
                            return i.merge(
                                i,
                                fromJS({
                                    cancelled: 1,
                                    interviewEndTime: Date.now(),
                                })
                            );
                        }
                        return i;
                    })
                )
                .set(
                    "modalStatus",
                    fromJS({ status: "success", action: "bulk-cancel" })
                );

        case SET_EDIT_CUSTOM_QUESTION:
            return state.set("editCustomQuestion", action.question);
        case FETCH_NOTIFICATION_FEED:
            return state.setIn(["notificationFeed", "status"], "in-progress");
        case FETCH_NOTIFICATION_FEED_STATUS: {
            return state
                .setIn(["notificationFeed", "status"], action.status)
                .setIn(
                    ["notificationFeed", "data", "page_info"],
                    fromJS((action.notificationFeed || {}).page_info || {})
                )
                .setIn(
                    ["notificationFeed", "data", "entries"],
                    state
                        .getIn(
                            ["notificationFeed", "data", "entries"],
                            fromJS([])
                        )
                        .merge(
                            fromJS(
                                (action.notificationFeed || {}).entries || []
                            )
                        )
                );
        }
        case UPDATE_FAQS:
            return state.set("updateFaqsStatus", "in-progress");
        case UPDATE_FAQS_STATUS: {
            if (action.status === "success") {
                state = state.set("updateFaqsStatus", "success");

                if (action.faqs) {
                    state = state.setIn(
                        ["users", action.companyId, "settings", "faqs"],
                        fromJS(action.faqs || [])
                    );

                    if (action.faqs.length === 0) {
                        state = state.setIn(
                            [
                                "users",
                                action.companyId,
                                "settings",
                                "isFAQsEnabled",
                            ],
                            false
                        );
                    }
                }

                if (action.enable) {
                    state = state.setIn(
                        [
                            "users",
                            action.companyId,
                            "settings",
                            "isFAQsEnabled",
                        ],
                        action.enable === "enabled"
                    );
                }

                return state;
            } else {
                return state.set("updateFaqsStatus", "failure");
            }
        }
        case FETCH_ADA_AI_CONTACTS:
            return state.setIn(["contacts", "status"], "in-progress");
        case FETCH_ADA_AI_CONTACTS_STATUS:
            return state
                .setIn(["contacts", "status"], action.status)
                .setIn(["contacts", "data"], fromJS(action.contacts || []));
        case FETCH_ADA_AI_CONTACT_DOCUMENTS:
            return state.setIn(["documents", "status"], "in-progress");
        case FETCH_ADA_AI_CONTACT_DOCUMENTS_STATUS:
            return state.setIn(["documents", "status"], action.status).setIn(
                ["documents", "data"],
                action.status === "success"
                    ? state.getIn(["documents", "data"], fromJS({})).merge(
                          fromJS({
                              [action.contactId]: action.documents || {},
                          })
                      )
                    : state.getIn(["documents", "data"], fromJS({}))
            );
        case FETCH_ADA_AI_CONTACT_SESSIONS:
            return state.setIn(["sessions", "status"], "in-progress");
        case FETCH_ADA_AI_CONTACT_SESSIONS_STATUS:
            return state.setIn(["sessions", "status"], action.status).setIn(
                ["sessions", "data"],
                action.status === "success"
                    ? state.getIn(["sessions", "data"], fromJS({})).merge(
                          fromJS({
                              [action.isMultiContact
                                  ? "all"
                                  : action.contactId]: action.session || {},
                          })
                      )
                    : state.getIn(["sessions", "data"], fromJS({}))
            );
        case CREATE_ADA_AI_QUERY:
            return state
                .setIn(["query", action.sessionId, "status"], "in-progress")
                .setIn(["query", action.sessionId, "content"], action.query)
                .setIn(
                    ["query", action.sessionId, "currentLog"],
                    "Analyzing your query…"
                );
        case CREATE_ADA_AI_QUERY_STATUS:
            return state
                .setIn(["query", action.sessionId, "status"], action.status)
                .setIn(["query", action.sessionId, "queryId"], action.queryId)
                .setIn(
                    ["query", action.sessionId, "response"],
                    action.queryType === "recursive"
                        ? fromJS(JSON.parse(action.response || "{}"))
                        : action.response || ""
                )
                .setIn(
                    ["query", action.sessionId, "queryType"],
                    action.queryType || ""
                )
                .setIn(
                    ["query", action.sessionId, "currentLog"],
                    action.currentLog || ""
                );
        case UPSERT_DOCUMENTS:
            return state.set(
                "upsert",
                fromJS({
                    overallStatus: {
                        status: "in-progress",
                        readableStatus:
                            (action.documentUrls.length > 1 ? "File 1: " : "") +
                            "Processing the document",
                    },
                    eachStatus: [],
                })
            );
        case UPSERT_DOCUMENTS_STATUS: {
            return state.set(
                "upsert",
                fromJS({
                    overallStatus: {
                        status: action.status,
                        processingStatus: action.processingStatus,
                        readableStatus: action.readableStatus,
                    },
                    eachStatus: [
                        ...Object.values(action.processingStatus || {}),
                    ],
                })
            );
        }
        case GET_BLOCKED_EMAILS_SUCCESS:
            return state.setIn(["blockedEmails"], fromJS(action.blockedEmails));
        case DELETE_BLOCKED_EMAIL_SUCCESS:
            return state.setIn(
                ["blockedEmails"],
                state
                    .getIn(["blockedEmails"], fromJS([]))
                    .filter((email) => email !== action.email)
            );
        default:
            return state;
    }
}

export default dashboardReducer;
